import React from "react";
import {Helmet} from "react-helmet"
import { Link } from "gatsby";
import {css} from "@emotion/react"
// markup
const Select = () => {
  return (
    <main>
           <Helmet>
        <title>Leonardo Feynman</title>
      </Helmet>
      <h1 css={css`margin: 0`}>Select Character</h1>
      <div>
      <h3>George Washington</h3> 
      <Link to="/us-president-simulator-george">  <img css={css`
       max-height: 150px;
      `} src="/washington.jpeg"/></Link>
      <h3>John Adams</h3> 
      <Link to="/us-president-simulator-2">  <img css={css`
       max-height: 150px;
      `} src="/johnadams.jpg"/></Link>
      <h3>Thomas Jefferson</h3>
      <Link to="/us-president-simulator-3">  <img css={css`
       max-height: 150px;
      `} src="/jeffy.jpeg"/></Link>
      </div>
    </main>
  )
}

export default Select
